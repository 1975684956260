import React, { useState, useRef, useEffect } from 'react';
import '../MasterCalendarPage.scss';
import RecurringCircleIcon from 'pages/CreateCampaign/Pages/SummaryPage/Components/Icons/SVG/RecurringCircleIcon';
import EditIcon from 'pages/CreateCampaign/Pages/SummaryPage/Components/Icons/SVG/EditIcon';
import CloseIcon from '@material-ui/icons/Close';
import styled, { css } from 'styled-components';
import Modal from 'components/UI/Modal';
import * as userActions from 'actions/user';
import { useAccount, useHttp } from 'hooks';
import {
  deleteCustomAvailabilityEntireSequence,
  updateCustomAvailability,
  updateCustomAvailabilityEntireSequence,
} from 'services/contributions.service';
import { FieldArray, Formik } from 'formik';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  TextField,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import TimePickerField from 'components/FormUI/TimePickerField';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as Yup from 'yup';

import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import CustomSelect from 'components/FormUI/Select';
import moment from 'moment';
import { BsDash } from 'react-icons/bs';
import Button from 'components/FormUI/Button';
import DropdownIcon from 'pages/CreateQuizes/Pages/QuizContent/Assests/Icons/DropdownIcon';
import Loader from 'components/UI/Loader';
import Notification from 'pages/CreateCampaign/Components/Notification/Notification';
import { setCampaignNotification } from 'actions/campaign';
import Notifications from 'pages/Account/Notifications/Notifications';
import { isEmpty } from 'lodash';
import {
  fetchContributionTimes,
  fetchContributionTimesActions,
  fetchCustomContributionTimes,
  setMasterCalendarArray,
  setMasterCalendarAvailabilityPopUp,
  setMasterCalendarAvailabilityPopUpModalId,
} from 'actions/contributions';
import DeleteIcon from 'pages/CreateContribution/components/AvailabilityRecurring/Icons/DeleteIcon';
import { MasterCalendarAvailabilityModal } from './MasterCalendarAvailabilityModal';

const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
`;
const StyledBsDash = styled(BsDash)``;
const useStyles = makeStyles({
  checkboxLabel: {
    fontSize: 14,
    fontWeight: 900,
    fontFamily: 'Avenir',
    padding: '0 9px 0px 0px',
    letterSpacing: '1.25px',
  },
  root: {
    marginLeft: '-30px',
  },
  heading: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 350,
    lineHeight: '24.69px',
    textAlign: 'left',
    color: '#000000DE',
    paddingBottom: '5px',
  },
  allOccurrencesLink: {
    textDecoration: 'underline',
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    cursor: 'pointer',
    textAlign: 'left',
  },
  availableDays: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: 350,
    lineHeight: '24.69px',
    textAlign: 'left',
    color: '#000000DE',
    paddingBottom: '10px',
  },
  cancelBtn: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    fontSize: '14px',
    fontWeight: '900',
    borderRadius: '4px',
    color: '#000000',
    padding: '11px 24px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  doneBtn: {
    fontSize: '14px',
    fontWeight: '900',
    borderRadius: '4px',
    padding: '11px 30px',
  },
});

const RecuuringModalBodyText = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #000000de;
  padding: 0px 0px 20px 0px;
`;
const StyledEditIcon = styled.div`
  cursor: pointer;
  padding-left: 5px;
`;

const StyledRecurringIcon = styled.div`
  padding-left: 5px;
`;

const StyledCloseIcon = styled.div`
  cursor: pointer;
`;
const ActionIconsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledCustomSlot = styled.div`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  color: white !important;
  display: flex;
  flex-direction: row;
  gap: 5px;
  white-space: nowrap;
  transition: all 0.3s ease;
  width: ${({ activeView }) => !activeView && '100%'};
  margin-top: ${({ activeView }) => (activeView ? '5px' : '')};

  .hover-title {
    width: ${({ activeView }) => !activeView && '100%'};
  }

  .hover-title {
    width: ${({ isRecurring, isCustomSlot, activeView }) =>
      activeView ? '' : isCustomSlot === false && activeView === false ? '78%' : isRecurring ? '50%' : '65%'};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const EditRecurringModalBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  border-top: 0.8px solid #e7e7e7;
  ${({ mobileView }) =>
    mobileView
      ? css`
          padding: 10px 0px 10px 0px;
          flex-direction: column;
        `
      : css`
          padding: 15px 0px 0px 0px;
        `};
`;
const StyledOneOccrenceBtn = styled(Button)`
  ${({ mobileView }) =>
    mobileView
      ? css`
          padding: 9px 20px;
          min-width: 22rem !important;
          border: 1px solid #c9b382;
          font-size: 14px;
        `
      : css`
          padding: 11px 30px;
        `};
`;
const StyledAllOccrenceBtn = styled(Button)`
  background: transparent;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 900;
  line-height: 16px;
  text-align: center;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.87);
  ${({ mobileView }) =>
    mobileView
      ? css`
          padding: 9px 20px;
          min-width: 22rem !important;
        `
      : css`
          padding: 11px 30px;
        `};
  &:hover {
    background: transparent;
  }
`;

const StyledTextField = styled(TextField)`
  border: 1px solid rgb(231, 231, 231) !important;
  ${({ error }) =>
    error &&
    `
 border: 1px solid red !important;
`}
  border-radius: 4px !important;
  font-family: Avenir !important;
  & .MuiInputBase-input {
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 350 !important;
    outline: none !important;
    padding: 13px 5px !important;
  }
  && .MuiInput-underline:before {
    border-bottom: 1px solid transparent; /* Default color */
  }
  && .MuiInput-underline:after {
    border-bottom: 1px solid transparent;
  }
  && .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid transparent;
  }
`;

const CustomSlot = styled.span``;

const SimpleSlot = styled.span``;

const MyCustomEvent = ({ event, activeView, setShowLoader = () => {}, eventsAvailability = [] }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { selectedMasterCalendarContribution } = useSelector(state => state.masterCalendar);
  const { activeContribution } = useSelector(state => state.contributions);
  const { showAvailabilityPopUp, masterCalendarModalId } = useSelector(state => state.contributions);
  const [overlappingAllowed, setOverlappingAllowed] = useState(false);
  const [recurrencePattern, setRecurrencePattern] = useState(null);
  const datetimeRef = useRef(null);
  const datetimeRefSecond = useRef(null);
  const editRecurringFormRef = useRef(null);
  const [editSelectedOccurrence, setEditSelectedOccurrence] = useState(false);
  const [availabilityInReviewToEdit, setAvailabilityInReviewToEdit] = useState(null);
  const [editEntireSeries, setEditEntireSeries] = useState(false);
  const [availabilityInReviewToDelete, setAvailabilityInReviewToDelete] = useState(null);
  const filteredEvents = eventsAvailability.filter(item => event?.customAvailabilityId === item.customAvailabilityId);
  useEffect(() => {
    setShowLoader(false);
  }, []);

  const getDayNumber = day => {
    const daysOfWeek = {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    };

    return daysOfWeek[day.toLowerCase()] ?? -1;
  };
  const handleDatePickRecurringEdit = selectedMomentDate => {
    const selectedDate = selectedMomentDate.format();
    editRecurringFormRef.current.setFieldValue('endDate', selectedDate);
  };
  const getDaysFromNumbers = numbers => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    return numbers.map(num => daysOfWeek[num] ?? 'Invalid');
  };
  const WeekValues = [
    { title: 'Monday', value: 'Monday' },
    { title: 'Tuesday', value: 'Tuesday' },
    { title: 'Wednesday', value: 'Wednesday' },
    { title: 'Thursday', value: 'Thursday' },
    { title: 'Friday', value: 'Friday' },
    { title: 'Saturday', value: 'Saturday' },
    { title: 'Sunday', value: 'Sunday' },
  ];
  const findCountOf = date => {
    const c = date / 7;
    if (c > 4) {
      return 5;
    } else if (c > 3 && (c < 4 || c === 4)) {
      return 4;
    } else if (c > 2 && (c < 3 || c === 3)) {
      return 3;
    } else if (c > 1 && (c < 2 || c === 2)) {
      return 2;
    } else {
      return 1;
    }
  };
  function isLastWeekOfMonth(year, month, day) {
    // Create a new date object with the selected date
    var selectedDate = new Date(year, month - 1, day);
    // Get the total number of days in the month
    var lastDay = new Date(year, month, 0).getDate();
    // Calculate the day of the week for the last day of the month
    var lastDayOfWeek = new Date(year, month - 1, lastDay).getDay();
    // Calculate the day of the week for the selected date
    var selectedDayOfWeek = selectedDate.getDay();
    // Determine if the selected date falls in the last week
    return lastDay - day <= 6 && selectedDayOfWeek >= lastDayOfWeek;
  }
  const todayDay = moment().format('dddd');
  const todayDate = moment().format('D');
  const handleClick = () => {
    if (datetimeRef.current) {
      datetimeRef.current.click();
    }
  };
  const handleClickSecond = () => {
    if (datetimeRefSecond.current) {
      datetimeRefSecond.current.click();
    }
  };

  const handleEditsubmit = () => {
    setShowLoader(true);
    const startTime = moment(editRecurringFormRef.current.values.startTime);
    const endTime = moment(editRecurringFormRef.current.values.endTime);
    const startTimeString = startTime.format('HH:mm:ss');
    const endTimeString = endTime.format('HH:mm:ss');
    if (editEntireSeries) {
      if (
        startTimeString > endTimeString ||
        startTimeString === endTimeString ||
        (editRecurringFormRef.current.values?.recurrencePattern === 'weekly' &&
          editRecurringFormRef.current.values.daysOfWeek.length === 0) ||
        (editRecurringFormRef.current.values?.recurrencePattern === 'monthly' &&
          editRecurringFormRef.current.values.repeatOn.length === 0)
      ) {
        if (
          (editRecurringFormRef.current.values?.recurrencePattern === 'weekly' &&
            editRecurringFormRef.current.values.daysOfWeek.length === 0) ||
          (editRecurringFormRef.current.values?.recurrencePattern === 'monthly' &&
            editRecurringFormRef.current.values.repeatOn.length === 0)
        ) {
          setShowLoader(false);
        } else {
          editRecurringFormRef.current.setErrors({ endTime: 'End time must be greater than Start time' });
          setShowLoader(false);
        }
      } else {
        const date = moment(editRecurringFormRef.current.values.date).format('YYYY-MM-DD');
        const endDay = moment(`${date} ${editRecurringFormRef.current.values.endTime.format('HH:mm:00')}`).format(
          'YYYY-MM-DDTHH:mm:00',
        );
        const startDay = moment(`${date} ${editRecurringFormRef.current.values.startTime.format('HH:mm:00')}`).format(
          'YYYY-MM-DDTHH:mm:00',
        );
        const oneToOneTimeRange = {
          endTime: endDay,
          startTime: startDay,
        };
        const dataToUpdate = {
          contributionIds: editRecurringFormRef.current.values?.contributions,
          dateTime: moment(availabilityInReviewToEdit.start).format('YYYY-MM-DDTHH:mm:00'),
          sessionDuration: activeContribution.durations[0] || 60,
          ...oneToOneTimeRange,
          customDate: availabilityInReviewToEdit.start,
          timezone: user.timeZoneId,
          oldCustomAvailability: {
            startTime: availabilityInReviewToEdit.start,
            endTime: availabilityInReviewToEdit.end,
          },
          initialRangeStartTime: startDay,
          initialRangeEndTime: endDay,
          customAvailabilityId: availabilityInReviewToEdit.customAvailabilityId,
          makeCustomAvailabilityRecurring: editRecurringFormRef.current.values.isResubmissionVal,
          recurringSessions: {
            interval: editRecurringFormRef.current.values?.interval,
            recurrencePattern: editRecurringFormRef.current.values?.recurrencePattern,
            repeatOn: editRecurringFormRef.current.values?.repeatOn,
            repeatOnData: editRecurringFormRef.current.values?.repeatOnData,
            endson: editRecurringFormRef.current.values?.endson,
            endDate: moment(editRecurringFormRef.current.values?.endDate).format('YYYY-MM-DDTHH:mm:00'),
            occurrences: editRecurringFormRef.current.values?.occurrences,
            occurrence: editRecurringFormRef.current.values?.occurrence,
            daysOfWeek: editRecurringFormRef.current.values?.daysOfWeek,
            isDayOfWeek: editRecurringFormRef.current.values?.isDayOfWeek,
            dayOfMonth: editRecurringFormRef.current.values?.dayOfMonth,
            weekOfMonth: editRecurringFormRef.current.values?.weekOfMonth,
            dayOfWeek: moment(startDay).format('dddd'),
          },
        };

        updateCustomAvailabilityEntireSequence(dataToUpdate)
          .then(res => {
            if (res.includes('Overlapping slots found')) {
              setOverlappingAllowed(true);
            } else {
              dispatch(
                setCampaignNotification({
                  message: 'test',
                  heading: 'test',
                }),
              );
              dispatch(fetchCustomContributionTimes(selectedMasterCalendarContribution));
              dispatch(fetchContributionTimes(selectedMasterCalendarContribution, true));
              setTimeout(() => {
                dispatch(setCampaignNotification({}));
              }, 4000);
            }
            setShowLoader(false);
            setEditEntireSeries(false);
            setEditSelectedOccurrence(false);
            console.log('res of update API-->', res);
            setRecurrencePattern(null);
            setAvailabilityInReviewToEdit(null);
            setEditEntireSeries(false);
            setEditEntireSeries(false);
            setEditSelectedOccurrence(false);
          })
          .catch(err => {
            setShowLoader(false);
            console.log('err', err);
            setRecurrencePattern(null);
            setAvailabilityInReviewToEdit(null);
            setEditEntireSeries(false);
            setEditSelectedOccurrence(false);
            dispatch(setCampaignNotification({}));
          });
      }
    } else {
      if (startTimeString > endTimeString || startTimeString === endTimeString) {
        editRecurringFormRef.current.setErrors({ endTime: 'End time must be greater than Start time' });
        setShowLoader(false);
      } else {
        editRecurringFormRef.current.setErrors({});

        const date = moment(editRecurringFormRef.current.values.date).format('YYYY-MM-DD');
        const endDay = moment(`${date} ${editRecurringFormRef.current.values.endTime.format('HH:mm:00')}`).format(
          'YYYY-MM-DDTHH:mm:00',
        );
        const startDay = moment(`${date} ${editRecurringFormRef.current.values.startTime.format('HH:mm:00')}`).format(
          'YYYY-MM-DDTHH:mm:00',
        );
        const oneToOneTimeRange = {
          endTime: endDay,
          startTime: startDay,
        };
        const dataToUpdate = {
          contributionIds: editRecurringFormRef.current.values?.contributions.join(', '),
          dateTime: moment(availabilityInReviewToEdit.start).format('YYYY-MM-DDTHH:mm:00'),
          sessionDuration: activeContribution.durations[0] || 60,
          newTimeRange: oneToOneTimeRange,
          isDelete: false,
          customAvailabilityId: availabilityInReviewToEdit.customAvailabilityId,
        };
        updateCustomAvailability(dataToUpdate)
          .then(res => {
            if (res.includes('Overlapping slots found')) {
              setOverlappingAllowed(true);
            } else {
              dispatch(
                setCampaignNotification({
                  message: 'test',
                  heading: 'test',
                }),
              );
              dispatch(fetchCustomContributionTimes(selectedMasterCalendarContribution));
              dispatch(fetchContributionTimes(selectedMasterCalendarContribution, true));
              setTimeout(() => {
                dispatch(setCampaignNotification({}));
              }, 4000);
            }
            setShowLoader(false);
            setEditEntireSeries(false);
            setEditSelectedOccurrence(false);
            console.log('res of update API-->', res);
            setRecurrencePattern(null);
            setAvailabilityInReviewToEdit(null);
            setEditEntireSeries(false);
          })
          .catch(err => {
            setShowLoader(false);
            console.log('err', err);
            setRecurrencePattern(null);
            setAvailabilityInReviewToEdit(null);
            setEditEntireSeries(false);
          });
      }
    }
  };

  const handleDeleteAvailiability = item => {
    setShowLoader(true);
    const dataToDelete = {
      contributionIds: selectedMasterCalendarContribution,
      dateTime: moment(item?.start).format('YYYY-MM-DDTHH:mm:00'),
      sessionDuration: activeContribution.durations[0] || 60,
      newTimeRange: null,
      isDelete: true,
      customAvailabilityId: item.customAvailabilityId,
    };
    updateCustomAvailability(dataToDelete)
      .then(res => {
        dispatch(
          setCampaignNotification({
            message: 'DELETE',
            heading: 'DELETE',
            isModal: item?.isModal,
          }),
        );
        dispatch(fetchCustomContributionTimes(selectedMasterCalendarContribution));
        dispatch(fetchContributionTimes(selectedMasterCalendarContribution, true));
        setShowLoader(false);
        setAvailabilityInReviewToDelete(null);
        setAvailabilityInReviewToDelete(false);
        setRecurrencePattern(null);
        setAvailabilityInReviewToEdit(null);
        setEditEntireSeries(false);
        setTimeout(() => {
          dispatch(setCampaignNotification({}));
        }, 4000);
      })
      .catch(err => {
        console.log('err', err);
        setShowLoader(false);
        setRecurrencePattern(null);
        setAvailabilityInReviewToEdit(null);
        setEditEntireSeries(false);
      });
  };

  const renderSlotContent = () => (
    <StyledCustomSlot
      activeView={activeView === 'Day'}
      isCustomSlot={event.isCustomSlot}
      isRecurring={event.recurrenceSettings != null && event.recurrencePattern > 1}
    >
      <div className={event.isCustomSlot ? 'hover-title' : ''}>{event.title}</div>
      <ActionIconsWrapper>
        {event.isCustomSlot && event.recurrenceSettings != null && event.recurrencePattern > 1 && (
          <StyledRecurringIcon>
            <RecurringCircleIcon />
          </StyledRecurringIcon>
        )}
        <StyledEditIcon
          isCustomSlot={event.isCustomSlot}
          onClick={() => {
            if (event.isCustomSlot) {
              dispatch(setCampaignNotification({}));
              if (event.recurrencePattern > 1) {
                setRecurrencePattern(event.recurrencePattern);
              }
              if (
                event.recurrencePattern === null ||
                event.recurrencePattern == 1 ||
                event.recurrenceSettings === null
              ) {
                setAvailabilityInReviewToEdit(event);
                setEditSelectedOccurrence(true);
              } else {
                setAvailabilityInReviewToEdit(event);
              }
            } else {
              dispatch(setMasterCalendarAvailabilityPopUpModalId(event.id));
              dispatch(setMasterCalendarAvailabilityPopUp(true));
              dispatch(fetchCustomContributionTimes(selectedMasterCalendarContribution));
              dispatch(
                setMasterCalendarArray([{ label: activeContribution.title, id: selectedMasterCalendarContribution }]),
              );
            }
          }}
        >
          <EditIcon color="white" width="14px" height="14px" />
        </StyledEditIcon>
        {event.isCustomSlot && (
          <StyledCloseIcon
            onClick={() => {
              dispatch(setCampaignNotification({}));
              if (event.recurrencePattern > 1) {
                setRecurrencePattern(event.recurrencePattern);
              }
              setAvailabilityInReviewToDelete(event);
            }}
          >
            <CloseIcon fontSize="small" />
          </StyledCloseIcon>
        )}
      </ActionIconsWrapper>
      {availabilityInReviewToEdit &&
        availabilityInReviewToEdit.recurrencePattern != null &&
        availabilityInReviewToEdit.recurrencePattern != 1 && (
          <Modal
            title="Edit Recurring Availability"
            isOpen={availabilityInReviewToEdit}
            isCreatingContribution={true}
            brandingColor={false}
            disableOverlayClick
            onCancel={() => {
              setRecurrencePattern(null);
              setAvailabilityInReviewToEdit(null);
              setEditEntireSeries(false);
            }}
            disableFooter={true}
            minHeight={'170px'}
          >
            <RecuuringModalBodyText>
              Please confirm if you’d like to edit the entire series or single occurrence.
            </RecuuringModalBodyText>
            <EditRecurringModalBtns mobileView={mobileView}>
              {recurrencePattern != null &&
                availabilityInReviewToEdit.recurrenceSettings != null &&
                filteredEvents[0]?.id === event?.id && (
                  <StyledAllOccrenceBtn
                    mobileView={mobileView}
                    onClick={() => {
                      setEditEntireSeries(true);
                      setEditSelectedOccurrence(true);
                      dispatch(setCampaignNotification({}));
                    }}
                  >
                    Edit Entire Series
                  </StyledAllOccrenceBtn>
                )}
              <StyledOneOccrenceBtn
                mobileView={mobileView}
                autoWidth
                onClick={() => {
                  setEditSelectedOccurrence(true);
                  dispatch(setCampaignNotification({}));
                }}
              >
                Edit This Occurrence
              </StyledOneOccrenceBtn>
            </EditRecurringModalBtns>
          </Modal>
        )}
      {availabilityInReviewToDelete && (
        <Modal
          title={
            recurrencePattern != null &&
            availabilityInReviewToDelete.recurrenceSettings != null &&
            recurrencePattern > 1
              ? 'Delete Recurring Availability'
              : 'Delete Availability'
          }
          isCreatingContribution={true}
          brandingColor={false}
          disableOverlayClick
          isOpen={availabilityInReviewToDelete}
          onCancel={() => {
            setRecurrencePattern(null);
            setAvailabilityInReviewToDelete(false);
          }}
          disableFooter={true}
          minHeight={'170px'}
        >
          {recurrencePattern != null &&
          availabilityInReviewToDelete.recurrenceSettings != null &&
          recurrencePattern > 1 ? (
            <RecuuringModalBodyText>
              Please confirm if you’d like to delete the entire series or single occurrence.
            </RecuuringModalBodyText>
          ) : (
            <RecuuringModalBodyText>Are you sure you want to delete this availability?</RecuuringModalBodyText>
          )}
          <EditRecurringModalBtns mobileView={mobileView}>
            {availabilityInReviewToDelete.recurrenceSettings != null && recurrencePattern > 1 && (
              <StyledAllOccrenceBtn
                mobileView={mobileView}
                onClick={() => {
                  setShowLoader(true);
                  deleteCustomAvailabilityEntireSequence({
                    customAvailabilityId: availabilityInReviewToDelete.customAvailabilityId,
                    contributionId: selectedMasterCalendarContribution,
                    timeRange: {
                      startTime: availabilityInReviewToDelete.start,
                      endTime: availabilityInReviewToDelete.end,
                    },
                  })
                    .then(() => {
                      dispatch(fetchCustomContributionTimes(selectedMasterCalendarContribution));
                      dispatch(fetchContributionTimes(selectedMasterCalendarContribution, true));
                      dispatch(
                        setCampaignNotification({
                          message: 'DELETE',
                          heading: 'DELETE',
                        }),
                      );

                      setShowLoader(false);
                      setAvailabilityInReviewToDelete(false);
                      setTimeout(() => {
                        dispatch(setCampaignNotification({}));
                      }, 4000);
                    })
                    .catch(() => {
                      setShowLoader(false);
                    });
                }}
                autoWidth
              >
                Delete Entire Series
              </StyledAllOccrenceBtn>
            )}
            {availabilityInReviewToDelete.recurrenceSettings != null && recurrencePattern > 1 && (
              <StyledOneOccrenceBtn
                mobileView={mobileView}
                autoWidth
                onClick={() => handleDeleteAvailiability(availabilityInReviewToDelete)}
              >
                Delete This Occurrence
              </StyledOneOccrenceBtn>
            )}
            {(availabilityInReviewToDelete.recurrenceSettings != null && recurrencePattern > 1) === false && (
              <StyledOneOccrenceBtn
                mobileView={mobileView}
                autoWidth
                onClick={() => {
                  setRecurrencePattern(null);
                  setAvailabilityInReviewToDelete(false);
                }}
              >
                No
              </StyledOneOccrenceBtn>
            )}
            {(availabilityInReviewToDelete.recurrenceSettings != null && recurrencePattern > 1) === false && (
              <StyledOneOccrenceBtn
                mobileView={mobileView}
                autoWidth
                onClick={() => handleDeleteAvailiability(availabilityInReviewToDelete)}
              >
                Yes
              </StyledOneOccrenceBtn>
            )}
          </EditRecurringModalBtns>
        </Modal>
      )}
      {editSelectedOccurrence && (
        <Modal
          title={'Edit Availability'}
          isOpen={editSelectedOccurrence}
          isCreatingContribution={true}
          brandingColor={false}
          disableOverlayClick
          onCancel={() => {
            setEditEntireSeries(false);
            setEditSelectedOccurrence(false);
            setRecurrencePattern(null);
            setAvailabilityInReviewToEdit(null);
            setEditEntireSeries(false);
            dispatch(setCampaignNotification({}));
          }}
          minWidth={mobileView ? '300px' : '600px'}
          max-width={mobileView ? '300px' : '600px'}
          disableFooter={true}
        >
          <Formik
            innerRef={editRecurringFormRef}
            initialValues={{
              contributions: selectedMasterCalendarContribution,
              startTime: moment(availabilityInReviewToEdit?.start),
              endTime: moment(availabilityInReviewToEdit?.end),
              date: moment(availabilityInReviewToEdit?.start || new Date()).format('MMMM DD,YYYY'),
              contributions: [selectedMasterCalendarContribution],
              interval: availabilityInReviewToEdit?.recurrenceSettings?.interval || 0,
              recurrencePattern: availabilityInReviewToEdit?.recurrenceSettings?.recurrencePattern,
              repeatOn: availabilityInReviewToEdit?.recurrenceSettings?.repeatOn || [],
              repeatOnData: availabilityInReviewToEdit?.recurrenceSettings?.repeatOnData || [],
              endson: availabilityInReviewToEdit?.recurrenceSettings?.endson || true,
              makeCustomAvailabilityRecurring:
                availabilityInReviewToEdit?.recurrencePattern != null ||
                availabilityInReviewToEdit?.recurrenceSettings != null
                  ? true
                  : false,
              occurrences: null,
              occurrence: false,
              daysOfWeek: availabilityInReviewToEdit?.recurrenceSettings?.daysOfWeek
                ? getDaysFromNumbers(availabilityInReviewToEdit?.recurrenceSettings?.daysOfWeek)
                : [],
              isDayOfWeek: availabilityInReviewToEdit?.recurrenceSettings?.isDayOfWeek || false,
              dayOfMonth: 1,
              weekOfMonth: 1,
              dayOfWeek: availabilityInReviewToEdit?.recurrenceSettings?.dayOfWeek || 'Sunday',
              isResubmissionVal:
                availabilityInReviewToEdit?.recurrencePattern != null ||
                availabilityInReviewToEdit?.recurrenceSettings != null
                  ? true
                  : false,
              endDate: availabilityInReviewToEdit?.recurrenceSettings?.endDate
                ? moment(availabilityInReviewToEdit.recurrenceSettings.endDate)
                : moment().add(1, 'year'),
            }}
            validationSchema={Yup.object().shape({
              startTime: Yup.string().required('This is a required field').nullable(),
              endTime: Yup.string().required('This is a required field').nullable(),
              interval: Yup.number().min(1, 'it must be 1 or more').required('This is a required field').nullable(),
              recurrencePattern: Yup.string().required('This is a required field'),
              date: Yup.string().required('This is a required field').nullable(),
              daysOfWeek: Yup.array().when('recurrencePattern', {
                is: v => v === 'weekly',
                then: Yup.array().required('This is a required field'),
                otherwise: Yup.array(),
              }),
              repeatOn: Yup.array().when('recurrencePattern', {
                is: v => v === 'monthly',
                then: Yup.array().required('This is a required field'),
                otherwise: Yup.array(),
              }),
              dayOfMonth: Yup.string().when('recurrencePattern', {
                is: v => v === 'monthly',
                then: Yup.string().required('This is a required field'),
                otherwise: Yup.string(),
              }),
              dayOfWeek: Yup.string().when('recurrencePattern', {
                is: v => v === 'monthly',
                then: Yup.string().required('This is a required field'),
                otherwise: Yup.string(),
              }),
              weekOfMonth: Yup.string().when('recurrencePattern', {
                is: v => v === 'monthly',
                then: Yup.string().required('This is a required field'),
                otherwise: Yup.string(),
              }),
            })}
            onSubmit={handleEditsubmit}
          >
            {({ values, setFieldValue, errors, touched }) => {
              const changeRepeatOnValues = e => {
                if (e?.target?.value === 'daily') {
                  setFieldValue('recurrencePattern', e?.target?.value);
                } else if (e?.target?.value === 'weekly') {
                  setFieldValue('repeatOnData', WeekValues);
                  setFieldValue('daysOfWeek', []);
                  setFieldValue('recurrencePattern', e?.target?.value);
                } else if (e?.target?.value === 'monthly') {
                  const todayDay = moment(moment(values.date)).format('dddd');
                  const todayDate = moment(moment(values.startTime)).format('D');
                  const count = findCountOf(todayDate);
                  var year = new Date(moment()).getFullYear();
                  var month = new Date(moment()).getMonth();
                  var day = todayDate;
                  let lastWeek;
                  if (isLastWeekOfMonth(year, month, day)) {
                    lastWeek = true;
                    console.log('Selected date is in the last week of the month.');
                  } else {
                    lastWeek = false;
                    console.log('Selected date is not in the last week of the month.');
                  }
                  setFieldValue('repeatOnData', [
                    { title: `Monthly on Day ${todayDate}`, value: `Monthly on day ${todayDate}` },
                    {
                      title: `Monthly on ${
                        count === 1
                          ? '1st'
                          : count === 2
                          ? '2nd'
                          : count === 3
                          ? '3rd'
                          : count === 4 && lastWeek
                          ? 'Last'
                          : count === 5
                          ? 'Last'
                          : 'Last'
                      } ${todayDay}`,
                      value: `Monthly on ${count} ${todayDay}`,
                    },
                  ]);
                  setFieldValue('repeatOn', []);
                  setFieldValue('dayOfWeek', todayDay);
                  setFieldValue('weekOfMonth', count);
                  setFieldValue('recurrencePattern', e?.target?.value);
                } else {
                  setFieldValue('repeatOnData', null);
                  setFieldValue('repeatOn', []);
                  setFieldValue('recurrencePattern', e?.target?.value);
                }
              };
              const sortedArr1 = values?.daysOfWeek?.slice()?.sort(); // Sort a copy of arr1
              const sortedArr2 = availabilityInReviewToEdit?.recurrenceSettings?.daysOfWeek
                ? getDaysFromNumbers(availabilityInReviewToEdit?.recurrenceSettings?.daysOfWeek).slice().sort()
                : [];
              return (
                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '550px' }}>
                  <div style={{ marginBottom: '10px' }}>
                    {`The available time slots for this change will be determined based on your specified time increment
                   of ${
                     activeContribution?.sessionIncrements[0] == 0 ? '60' : activeContribution?.sessionIncrements[0]
                   } minutes.`}
                  </div>
                  {mobileView && (
                    <Grid style={{ marginBottom: '10px' }} lg={4} xs={12} sm={12}>
                      <Grid className={classes.heading} lg={12}>
                        Date
                      </Grid>
                      <Grid lg={12} style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
                        <DatePicker
                          inputRef={datetimeRefSecond}
                          fullWidth
                          disablePast
                          onChange={momentDate => setFieldValue('date', moment(momentDate).format('MMMM DD,YYYY'))}
                          value={values.date}
                          format={'MMM DD, YYYY'}
                          inputVariant="outlined"
                        />
                        <InsertInvitationIcon
                          htmlColor="#757575"
                          style={{ position: 'absolute', right: '4px', top: '10px' }}
                          onClick={handleClickSecond}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid lg={12} style={{ display: 'flex', alignItems: 'baseline', gap: mobileView ? '5px' : '16px' }}>
                    {!mobileView && (
                      <Grid lg={4}>
                        <Grid className={classes.heading} lg={12}>
                          Date
                        </Grid>
                        <Grid lg={12} style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
                          <DatePicker
                            inputRef={datetimeRefSecond}
                            fullWidth
                            disablePast
                            onChange={momentDate => setFieldValue('date', moment(momentDate).format('MMMM DD,YYYY'))}
                            value={values.date}
                            format={'MMM DD, YYYY'}
                            inputVariant="outlined"
                          />
                          {mobileView === false && (
                            <InsertInvitationIcon
                              htmlColor="#757575"
                              style={{ position: 'absolute', right: '4px', top: '10px' }}
                              onClick={handleClickSecond}
                            />
                          )}
                        </Grid>
                      </Grid>
                    )}

                    <Grid lg={4} style={{ display: 'flex', flexDirection: 'column' }}>
                      <Grid className={classes.heading} lg={12}>
                        Start Time
                      </Grid>
                      <Grid lg={12}>
                        <TimePickerField
                          onChange={value => setFieldValue('startTime', moment(value))}
                          showSecond={false}
                          value={values.startTime}
                          format="h:mm a"
                          use12Hours
                          minuteStep={15}
                          style={{
                            fontFamily: 'Avenir',
                            border: '1px solid #e7e7e7',
                            fontWeight: 350,
                            fontSize: '16px',
                            borderRadius: '4px',
                            alignItems: 'center',
                            padding: '21px 5px',
                          }}
                          className="custom-time-picker-nounderline"
                        />
                        {editRecurringFormRef.current != null && touched.startTime && errors?.startTime && (
                          <CommonErrorMessage
                            className="default-font-family"
                            message={errors?.startTime}
                            align="left"
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid lg={4} style={{ display: 'flex', flexDirection: 'column' }}>
                      <Grid className={classes.heading} lg={12}>
                        End Time
                      </Grid>
                      <Grid lg={12}>
                        <TimePickerField
                          onChange={value => setFieldValue('endTime', moment(value))}
                          showSecond={false}
                          value={values.endTime}
                          format="h:mm a"
                          use12Hours
                          minuteStep={15}
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: 350,
                            fontSize: '16px',
                            border: '1px solid #e7e7e7',
                            borderRadius: '4px',
                            alignItems: 'center',
                            padding: '21px 5px',
                          }}
                          className="custom-time-picker-nounderline"
                        />
                        {errors?.endTime && (
                          <CommonErrorMessage className="default-font-family" message={errors?.endTime} align="left" />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {editEntireSeries && (
                    <Grid style={{ marginTop: '10px', marginLeft: '20px' }} lg={12}>
                      <FormControlLabel
                        classes={{
                          label: classes.checkboxLabel,
                          root: classes.root,
                        }}
                        disabled={true}
                        checked={values.isResubmissionVal}
                        onChange={() => {
                          setFieldValue('isResubmissionVal', !values.isResubmissionVal);
                          setFieldValue('occurrences', null);
                          const todayDay = moment(moment()).format('dddd');
                          const todayDate = moment(moment()).format('D');
                          const count = findCountOf(todayDate);
                          var year = new Date(moment()).getFullYear();
                          var month = new Date(moment()).getMonth() + 1;
                          var day = todayDate;
                          let lastWeek;
                          if (isLastWeekOfMonth(year, month, day)) {
                            lastWeek = true;
                            console.log('Selected date is in the last week of the month.');
                          } else {
                            lastWeek = false;
                            console.log('Selected date is not in the last week of the month.');
                          }
                          if (values.recurrencePattern === 'monthly') {
                            setFieldValue('repeatOnData', [
                              { title: `Monthly on Day ${todayDate}`, value: `Monthly on day ${todayDate}` },
                              {
                                title: `Monthly on ${
                                  count === 1
                                    ? '1st'
                                    : count === 2
                                    ? '2nd'
                                    : count === 3
                                    ? '3rd'
                                    : count === 4 && lastWeek
                                    ? 'Last'
                                    : count === 5
                                    ? 'Last'
                                    : '4th'
                                } ${todayDay}`,
                                value: `Monthly on ${count} ${todayDay}`,
                              },
                            ]);
                          } else {
                            setFieldValue('repeatOnData', WeekValues);
                          }
                        }}
                        control={<Checkbox color="primary" />}
                        label="Make Availability Recurring"
                        name="isResubmissionVal"
                      />
                    </Grid>
                  )}
                  {values.isResubmissionVal && editEntireSeries && (
                    <>
                      <Grid
                        style={{
                          flexDirection: 'column',
                          display: 'flex',
                          gap: mobileView ? '5px' : '16px',
                        }}
                        lg={12}
                      >
                        <Grid lg={12}>
                          <Grid className={classes.heading}>Repeat Every</Grid>
                          <Grid style={{ display: 'flex', gap: '8px' }} lg={12}>
                            <Grid lg={6} sm={12} xs={12}>
                              <StyledTextField
                                style={{ fontFamily: 'Brandon Text', border: 'none' }}
                                name="interval"
                                value={values?.interval}
                                fullWidth
                                onChange={e => {
                                  const re = /^[0-9\b]+$/;
                                  if (e.target.value.toString() === '') {
                                    setFieldValue('interval', null);
                                  } else if (re.test(e?.target?.value)) {
                                    setFieldValue('interval', parseInt(e?.target?.value));
                                  }
                                }}
                                recurringField={true}
                                error={errors && errors?.interval}
                              />
                            </Grid>
                            <Grid lg={6} sm={12} xs={12}>
                              <StyledCustomSelect
                                showHelperText={false}
                                style={{ fontFamily: 'Brandon Text' }}
                                variant="outlined"
                                name="recurrencePattern"
                                defaultValue={values?.recurrencePattern}
                                fullWidth
                                onChange={changeRepeatOnValues}
                                className="custom-input-style"
                                items={[
                                  { value: 'daily', title: values?.interval > 1 ? 'Days' : 'Day' },
                                  {
                                    value: 'weekly',
                                    title: values?.interval > 1 ? 'Weeks' : 'Week',
                                  },
                                  {
                                    value: 'monthly',
                                    title: values?.interval > 1 ? 'Months' : 'Month',
                                  },
                                  {
                                    value: 'yearly',
                                    title: values?.interval > 1 ? 'Years' : 'Year',
                                  },
                                ]}
                                roundedBorder={true}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid style={{ display: 'flex', gap: '8px' }} lg={12}>
                          {(values?.recurrencePattern === 'weekly' || values?.recurrencePattern === 'monthly') && (
                            <Grid lg={6} sm={6} xs={6}>
                              <Grid className={classes.heading}>Repeat On</Grid>
                              <Grid container xs={12} sm={12}>
                                {values.recurrencePattern === 'weekly' ? (
                                  <StyledCustomSelect
                                    showHelperText={false}
                                    underLineColor={'transparent'}
                                    name="daysOfWeek"
                                    multiple
                                    className="custom-input-style"
                                    items={WeekValues}
                                    fullWidth={mobileView ? false : true}
                                    style={{ marginTop: '0px' }}
                                    roundedBorder={true}
                                  />
                                ) : (
                                  <>
                                    <StyledCustomSelect
                                      showHelperText={false}
                                      underLineColor={'transparent'}
                                      name="repeatOn"
                                      style={{ marginTop: '0px' }}
                                      className="custom-input-style"
                                      fullWidth
                                      value={values?.repeatOn}
                                      roundedBorder={true}
                                      onChange={e => {
                                        if (e?.target?.value === `Monthly on day ${todayDate}`) {
                                          setFieldValue('isDayOfWeek', false);
                                          setFieldValue('dayOfMonth', parseInt(todayDate));
                                        } else {
                                          const count = findCountOf(todayDate);
                                          setFieldValue('isDayOfWeek', true);
                                          setFieldValue('dayOfWeek', todayDay);
                                          setFieldValue('weekOfMonth', parseInt(count));
                                        }
                                        setFieldValue('repeatOn', [e?.target?.value]);
                                      }}
                                      items={values?.repeatOnData}
                                    />
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          )}
                          <Grid lg={6}>
                            <div style={{ display: 'flex', flexDirection: 'column' }} className="default-font-family">
                              <div className={classes.heading}>End Date</div>
                              <div>
                                <div
                                  style={{
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <DatePicker
                                    inputRef={datetimeRef}
                                    fullWidth
                                    disablePast
                                    onChange={momentDate => handleDatePickRecurringEdit(momentDate)}
                                    value={values.endDate}
                                    format={'MMM DD, YYYY'}
                                    minDate={
                                      moment(availabilityInReviewToEdit?.recurrenceSettings?.endDate).format(
                                        'YYYY-MM-DD',
                                      ) === moment(availabilityInReviewToEdit?.start).format('YYYY-MM-DD')
                                        ? moment(availabilityInReviewToEdit?.recurrenceSettings?.endDate).subtract(
                                            1,
                                            'day',
                                          )
                                        : moment(values.date).add(1, 'day')
                                    }
                                    TextFieldComponent={props => <StyledTextField {...props} />}
                                  />
                                  <div style={{ cursor: 'pointer' }}>
                                    <DropdownIcon width="12" height="7" color="#00000066" availability={true} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <div style={{ display: 'flex', gap: '10px', padding: '15px 0px' }}>
                    <Button
                      invert
                      autoWidth
                      className={classes.cancelBtn}
                      onClick={() => {
                        setEditSelectedOccurrence(false);
                        dispatch(setCampaignNotification({}));
                        setAvailabilityInReviewToEdit(null);
                        setEditEntireSeries(false);
                        setEditSelectedOccurrence(false);
                        setRecurrencePattern(null);
                        setEditEntireSeries(false);
                      }}
                    >
                      Cancel
                    </Button>
                    {editEntireSeries ? (
                      <Button
                        autoWidth
                        className={classes.doneBtn}
                        onClick={() => {
                          handleEditsubmit();
                        }}
                        disabled={
                          !values.startTime ||
                          !values.endTime ||
                          moment(moment(values.endDate).format('YYYY-MM-DD')).isBefore(
                            moment(moment(values.date).format('YYYY-MM-DD')).format('YYYY-MM-DD'),
                          ) ||
                          moment(moment(values.endDate).format('YYYY-MM-DD')).isSame(
                            moment(moment(values.date).format('YYYY-MM-DD')).format('YYYY-MM-DD'),
                          ) ||
                          moment(values.endTime).format('HH:mm:ss') === moment(values.startTime).format('HH:mm:ss') ||
                          (moment(values.date).format('MMMM DD, YYYY') ===
                            moment(availabilityInReviewToEdit?.start).format('MMMM DD, YYYY') &&
                            moment(values.startTime).format('MMMM DD, YYYY, h:mm A') ===
                              moment(availabilityInReviewToEdit?.start).format('MMMM DD, YYYY, h:mm A') &&
                            moment(values.endTime).format('MMMM DD, YYYY, h:mm A') ===
                              moment(availabilityInReviewToEdit?.end).format('MMMM DD, YYYY, h:mm A') &&
                            values.interval === availabilityInReviewToEdit.recurrenceSettings.interval &&
                            values.recurrencePattern ===
                              availabilityInReviewToEdit.recurrenceSettings.recurrencePattern &&
                            moment(values.endDate).format('MMMM DD, YYYY') ===
                              moment(availabilityInReviewToEdit.recurrenceSettings.endDate).format('MMMM DD, YYYY') &&
                            values.repeatOn[0] === availabilityInReviewToEdit.recurrenceSettings.repeatOn[0] &&
                            values.daysOfWeek.length ===
                              availabilityInReviewToEdit.recurrenceSettings.daysOfWeek.length &&
                            sortedArr1.every((value, index) => value === sortedArr2[index]))
                        }
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        autoWidth
                        className={classes.doneBtn}
                        onClick={() => {
                          handleEditsubmit();
                        }}
                        disabled={
                          !values.startTime ||
                          !values.endTime ||
                          moment(values.endTime).format('HH:mm:ss') === moment(values.startTime).format('HH:mm:ss') ||
                          (moment(values.date).format('MMMM DD, YYYY') ===
                            moment(availabilityInReviewToEdit?.start).format('MMMM DD, YYYY') &&
                            moment(values.startTime).format('MMMM DD, YYYY, h:mm A') ===
                              moment(availabilityInReviewToEdit?.start).format('MMMM DD, YYYY, h:mm A') &&
                            moment(values.endTime).format('MMMM DD, YYYY, h:mm A') ===
                              moment(availabilityInReviewToEdit?.end).format('MMMM DD, YYYY, h:mm A') &&
                            values.endTime)
                        }
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </div>
              );
            }}
          </Formik>
        </Modal>
      )}
      {showAvailabilityPopUp && masterCalendarModalId === event.id && (
        <MasterCalendarAvailabilityModal
          showTerms={showAvailabilityPopUp}
          setshowAvailabilityPopUp={action => {
            // dispatch(oneToOneAvailabilityAction(false));
            dispatch(setMasterCalendarAvailabilityPopUp(action));
            dispatch(setMasterCalendarAvailabilityPopUpModalId(null));
          }}
          onCancel={() => {
            // dispatch(oneToOneAvailabilityAction(false));
            dispatch(setMasterCalendarAvailabilityPopUp(false));
            dispatch(setMasterCalendarAvailabilityPopUpModalId(null));
          }}
          incrementNewSlots={() => {}}
        />
      )}
    </StyledCustomSlot>
  );
  console.log('eventsAvailability---->', filteredEvents);
  return <CustomSlot>{renderSlotContent()}</CustomSlot>;
};

export default MyCustomEvent;
